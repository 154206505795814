import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SET_LANG,
  SIGNOUT_USER_SUCCESS,
  TWO_FA_ENABLED,
  TWO_FA_TOKEN,
  USER_DATA,
  USER_TOKEN_SET
} from "../../constants/ActionTypes";
import axios from "../../util/Api";
import create from "zustand";

export const setInitUrl = url => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const userSignUp = ({ email, password, name }) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios
      .post("auth/register", {
        email: email,
        password: password,
        name: name
      })
      .then(({ data }) => {
        console.log("data:", data);
        if (data.result) {
          localStorage.setItem(
            "token",
            JSON.stringify(data.token.access_token)
          );
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + data.token.access_token;
          dispatch({ type: SET_LANG, payload: '' })
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_TOKEN_SET, payload: data.token.access_token });
          dispatch({ type: USER_DATA, payload: data.user });
        } else {
          console.log("payload: data.error", data.error);
          dispatch({ type: FETCH_ERROR, payload: "Network Error" });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const validateTwoFaCode = ({ two_fa_code, two_fa_token, redirect }) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios
      .post("/auth/validate-2fa-code", { two_fa_code, two_fa_token })
      .then(({ data }) => {
        dispatch({ type: TWO_FA_ENABLED, payload: false });
        const lang = getLangText(data.user);
        dispatch({ type: SET_LANG, payload: lang })
        dispatch({ type: USER_DATA, payload: data.user });
        localStorage.setItem("LANG", lang);
        localStorage.setItem("token", data.access_token);
        localStorage.setItem("user", JSON.stringify(data.user));
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + data.access_token;
        axios.defaults.headers.common["Client-Version"] = localStorage.getItem('APP_VERSION');
        dispatch({ type: USER_TOKEN_SET, payload: data.access_token });
        dispatch({
          type: INIT_URL,
          payload: redirect ? `${redirect.pathname}${redirect.hash}` : "/home"
        });
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(err => {
        if (err.response.data.error) {
          dispatch({ type: FETCH_ERROR, payload: err.response.data.error });
          if (err.response.data.code && err.response.data.code === "01") {
            dispatch({ type: TWO_FA_ENABLED, payload: false });
            dispatch({
              type: INIT_URL,
              payload: "/signin"
            });
          }
        } else {
          dispatch({ type: FETCH_ERROR, payload: "Error" });
        }
      });
  };
};

export const updateAuthUser = ({ userData }) => {
  return { type: USER_DATA, payload: userData };
}

export const userSocialSignIn = ({ token }) => {
  return dispatch => {
    getUser(token)
    localStorage.setItem("token", token);
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + token;
    axios.defaults.headers.common["Client-Version"] = localStorage.getItem('APP_VERSION');
    dispatch({ type: USER_TOKEN_SET, payload: token });
    window.location.href = "/home"
  }
}

export const userSignIn = ({ email, password, token, redirect, setUser }) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios
      .post("/auth/login", {
        email: email,
        password: password,
        recaptcha_token: token
      })
      .then(({ data }) => {
        if (data.two_fa_enabled) {
          dispatch({ type: TWO_FA_TOKEN, payload: data.two_fa_token });
          dispatch({ type: TWO_FA_ENABLED, payload: true });
          dispatch({ type: FETCH_SUCCESS });
        } else {
          const lang = getLangText(data.user);
          dispatch({ type: SET_LANG, payload: lang })
          dispatch({ type: USER_DATA, payload: data.user });
          localStorage.setItem("LANG", lang);
          localStorage.setItem("token", data.access_token);
          localStorage.setItem("user", JSON.stringify(data.user));
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + data.access_token;
          axios.defaults.headers.common["Client-Version"] = localStorage.getItem('APP_VERSION');
          dispatch({ type: USER_TOKEN_SET, payload: data.access_token });
          dispatch({
            type: INIT_URL,
            payload: redirect ? `${redirect.pathname}${redirect.hash}` : "/home"
          });
          dispatch({ type: FETCH_SUCCESS });
        }
      })
      .catch(function (error) {
        const message = error.response.data.error || error.message
        dispatch({ type: FETCH_ERROR, payload: message });
      });
  };
};

export const getUser = (token = null) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios
      .get("auth/me", {
        headers: {
          Authorization: `Bearer ${token || localStorage.getItem("token")}`
        }
      })
      .then(({ data }) => {
        if (data) {
          dispatch({ type: FETCH_SUCCESS });
          const user = localStorage.getItem("user");
          if (JSON.stringify(data) != user) {
            dispatch({ type: USER_DATA, payload: data });
            localStorage.setItem("user", JSON.stringify(data));
          }
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const userSignOut = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    setTimeout(() => {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      delete axios.defaults.headers.common["Authorization"];
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: SIGNOUT_USER_SUCCESS });
    }, 2000);
  };
};

export const useUser = create(set => ({
  user: null,
  setUser: user => {
    set(state => ({ ...state, user }));
  }
}));

function getLangText(user) {
  if (!province && !user.studio) return 'base'

  const { province } = user
  const { province: studioProvince } = user.studio

  if (province && province.text) {
    return province.text
  } else if (studioProvince && studioProvince.country && studioProvince.country.text) {
    return studioProvince.country.text
  } else {
    return 'base'
  }
}