import { Divider, Empty, Select, Tooltip } from "antd";
import { LoadingOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import React, { useRef, useState } from "react";
import ClientService from "../../../services/client.service";
import { connect } from "react-redux";
import { ReactComponent as AddButton } from "../../../assets/assets2_0/add-button.svg";
import CreateClientModal from "../../../modals/CreateClientModal";
import { useMediaQuery } from "react-responsive";

const { Option } = Select;

const Antd4ClientSelect = ({
  showNewClientButton,
  onNewClient,
  onSelect,
  showClientType,
  value,
  onChange,
  disabled,
  style,
  placeholder = "Buscar cliente por nombre",
  clearOnSelect,
  currentLang,
  allowRawInput,
  showLinkContactButton = false
}) => {
  const [loading, setLoading] = useState(false);
  const [showClientModal, setShowClientModal] = useState(false);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 760px)' });

  const [data, setData] = useState([]);
  const [observer, setObserver] = useState();
  const [val, setVal] = useState(value);

  const debouncedSearch = word => {
    clearTimeout(observer);

    if (word.trim() !== "" && word !== "" && word !== null && word !== undefined) {
      setObserver(
        setTimeout(
          () =>
            getClients(word).then(c => {
              if (c) {
                setData(c);
              }
            }),
          1500
        )
      );
    } else {
      setData([]);
    }
  };

  const getClients = async word => {
    setLoading(true);
    const data = await ClientService.searchClient({
      search: word,
      per_page: 2000
    });
    let clients = data.data.data;

    if (allowRawInput) {
      let raw_client = { id: word, firstname: word, raw: true };
      onChange && onChange(raw_client)
      onSelect && onSelect(raw_client)
      clients = [raw_client].concat(clients)
      setVal(word)
    }

    setLoading(false);
    return clients;
  };

  return (
    <div className="custom-cliente-select-container">
      <CreateClientModal
        showLinkButton={showLinkContactButton}
        visible={showClientModal}
        onNewClient={(c) => {
          let updated_data = [...data];
          updated_data.push(c);
          setData(updated_data);
          setVal(c.id)
          onNewClient && onNewClient(c)
          setShowClientModal(false)

        }}
        onCancel={() => setShowClientModal(false)}
      />
      <Select className="responsive-input"
        placeholder={placeholder}
        style={{ ...style, maxWidth: showNewClientButton ? "85%" : "100%" }}
        disabled={disabled}
        loading={loading}
        showSearch
        value={val}
        allowClear
        onClear={() => {
          onChange && onChange({})
          onSelect && onSelect({})
          setVal(null)
        }}
        filterOption={false}
        suffixIcon={loading ? <LoadingOutlined /> : <SearchOutlined />}
        onSelect={v => {
          onChange && onChange(v);
          onSelect && onSelect(data.filter(x => x.id === v)[0]);
          if (clearOnSelect) {
            setData([])
            setVal(null)
          } else {
            setVal(v)
          }
        }}
        onSearch={v => {
          debouncedSearch(v);
        }}
        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Busque los contactos ya cargados por nombre, apellido y/o razón social" />}
      >
        {data.map(client => (
          <Option key={client.id} value={client.id}>
            <>
              <span>
                {client.firstname} {client.lastname}{" "}
              </span>{" "}

              {showClientType && (

                <span style={{ color: "#c7c7c7", fontSize: "12px" }}>
                  <br />{client.client_type ? client.client_type.name : ""}
                </span>
              )}
            </>
          </Option>
        ))}
      </Select>
      {showNewClientButton && <Tooltip trigger="hover" title="Crear cliente/contacto">
        <AddButton
          width="40"
          heigth="58"
          style={{ cursor: 'pointer' }}
          onClick={() => { setShowClientModal(true) }}
        />
      </Tooltip>}
    </div>
  );
};

const mapStateToProps = ({ lang }) => {
  const { currentLang } = lang
  return { currentLang };
};

export default connect(mapStateToProps, {})(Antd4ClientSelect);

Antd4ClientSelect.defaultProps = {
  showNewClientButton: true,
}