import React, { useCallback, useContext, useEffect } from 'react'
import { Calendar, Typography } from 'antd'
import { TurnoSelectorContext, calculateMinuteSteps, turnoDefaultHours } from './TurnoSelectorContext'
import moment from 'moment'
import AppointmentService from '../../../services/appointment.service'

export default function TDateSelect() {

  const { date, setDate, availableDays, user, duration, setSteps, availableHours, isOverturn, setBusyHours } = useContext(TurnoSelectorContext)

  const handleSelect = (d) => {
    setDate(d)
  }

  useEffect(() => {
    const fetchHoursAvailables = async () => {
      const { data } = await AppointmentService.hoursAvailables(user, date.format("YYYY-MM-DD"))
      setBusyHours(data)
      const h = hideBusySteps(data, calculateMinuteSteps(duration, isOverturn ? turnoDefaultHours : availableHours))
      setSteps(h)
    }
    if (date && user) {
      fetchHoursAvailables()
    }
  }, [date, user, duration, isOverturn])

  const disabledDate = useCallback((d) => {
    if (isOverturn) return false
    if (availableDays.length === 0) return false
    let dow = d.day()
    if (dow === 0) dow = 6
    else dow = dow - 1
    return !availableDays.includes(dow)
  }, [availableDays, isOverturn])

  return <div className='t-calendar'>
    <Typography.Text>Selecciona la fecha</Typography.Text>
    <Calendar disabledDate={d => disabledDate(d)} value={date || moment()} onSelect={handleSelect} fullscreen={false} />
  </div>

}

export function hideBusySteps(busySteps, steps) {
  const busyStepsDates = busySteps.map(s => {
    const startingHour = s.starting_time.split(':')[0]
    const endingHour = s.ending_time.split(':')[0]
    const startingMinute = s.starting_time.split(':')[1]
    const endingMinute = s.ending_time.split(':')[1]
    return [moment().set({ hour: startingHour, minutes: startingMinute }), moment({ hour: endingHour, minutes: endingMinute })]
  })
  const dateSteps = steps.map(s => {
    const h = s.split(':')
    return [moment().set({ hour: h[0], minutes: h[1] })]
  })
  const availableSteps = dateSteps.filter(s => {
    return !busyStepsDates.some(b => {
      return s[0].isBetween(b[0], b[1], undefined, '[]')
    })
  })
  return availableSteps.map(s => s[0].format('HH:mm'))
}