import { Typography, message } from 'antd';
import { Tag} from 'antd';
import moment from 'moment';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { CustomSwitch } from '../../CustomTabs/CustomTabs';
import ExpandCollpaseText from '../../ExpandCollapseText';
import DeleteMovementConfirm from '../components/DeleteMovementConfirm';
import { ColorBorder } from './MovementItem';
import ExpedientResponsibles from '../../ExpedientResponsibles';
import AppointmentService from '../../../services/appointment.service';

const AppointmentItem = ({ item,  onUpdated, onDelete }) => {

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const title = `CITA CON  ${item.client.firstname ? item.client.firstname.toUpperCase() : ""} ${item.client.lastname ? item.client.lastname.toUpperCase() : ""}`;

  return (
    <div
      style={{
        width: "100%",
        border: "1px solid #CCCCCC",
        borderRadius: `${isTabletOrMobile ? "14px" : "4px"} 14px 14px 4px`,
        background: "#fff",
        position: "relative",
        margin: "1ch 0px 0.5ch 0px"
      }}
    >
      <ColorBorder
        position={isTabletOrMobile ? "bottom" : "top"}
        color={
          "#038FDE"
        }
      />
      <div className="movement-item-container">
        <div>
          <div
            style={{
              display: "flex",
              gap: "1ch",
              flexFlow: isTabletOrMobile ? "column" : "row"
            }}
          >
            <Typography.Text style={{ fontWeight: "bold", fontSize: 13 }}>
              {title}
            </Typography.Text>
            <OverTimeTag appointment={item} />
          </div>
          <div style={{ display: "flex", gap: "1ch" }}>
            <ExpandCollpaseText copyFromText={true} rows={2} text={item.observation} />
          </div>
        </div>
        <div className="movement-item-status-container">
          <ExpedientResponsibles
            withLabel
            justifyContent='start'
            exp_responsibles={[item.user]}
          />
          <ToggleAppointmentStatus
            size="small"
            onUpdated={onUpdated}
            appointment={item}
          />
          <div className="movement-item-status-subcontainer">
            <div
              style={{ display: "flex", alignItems: "center", gap: "0.6ch" }}
            >
              <span>🗓</span> {moment(item.date).format("DD/MM/YYYY")}   <span>🕒</span> {moment(item.fullStartDate).format("hh:mm")} - {moment(item.fullEndDate).format("hh:mm A")}
            </div>
          </div>
        </div>
      </div>
      <div className="movement-actions-container">
        <div style={{ display: "flex", gap: "2ch", alignItems: "center" }}>
          <DeleteMovementConfirm
            itemType={"check"}
            deleteFunc={AppointmentService.deleteAppointment}
            isTabletOrMobile={isTabletOrMobile}
            item={item}
            onDelete={(task) => onDelete(task)}
          />
        </div>
      </div>
    </div>
  );
};

export default AppointmentItem;

const ToggleAppointmentStatus = ({ onUpdated, appointment, disabled, short }) => {

  async function toggleStatus(appointment) {
    const payload = {
      status: appointment.status == 'pending' ? 'attended' : 'pending'
    };
    const status = await AppointmentService.updateAppointment(payload, appointment.id);
    return status.data;
  }


  if (short) {
    return <CustomSwitch
      defaultValue={appointment.status !== "pending" ? 1 : 0}
      mode="toggle"
      options={[
        {
          name: "PENDIENTE",
          color: "#FF5879"
        },
        {
          name: "HECHO",
          color: "#24335D"
        }
      ]}
      onChange={async () => {
        const { status } = await toggleStatus(appointment);
        onUpdated({ ...appointment, status });
        message.success("Se ha actualizado el estado de la tarea.");
      }}
    />
  }

  return disabled ? null : (
    <CustomSwitch
      mode="toggle"
      defaultValue={appointment.status !== "pending" ? 1 : 0}
      options={[
        {
          name: "PENDIENTE",
          color: "#FF5879"
        },
        {
          name: "HECHO",
          color: "#24335D"
        }
      ]}
      onChange={async () => {
        const { status } = await toggleStatus(appointment);
        onUpdated({ ...appointment, status });
        message.success("Se ha actualizado el estado de la tarea.");
      }}
    />
  );
};

const OverTimeTag = ({ appointment }) => {
  return (
    <div className="import-judicial-tag">
      {appointment.out_of_shift ? (
        <Tag
          color="#8CB3FF"
          style={{ fontSize: ".65em", margin: "0.5px", height: "fit-content" }}
        >
          Sobreturno
        </Tag>
      ) : (
        ""
      )}
    </div>
  );
};