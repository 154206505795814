import { Popover } from "antd";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import ExpandCollpaseText from "../ExpandCollapseText";
import { ReactComponent as CandadoGris } from "../../assets/assets2_0/candado_gris.svg";

export const MovementObservations = ({
  record,
  updateMovementObservation,
  reload
}) => {
  const [_record, setRecord] = useState(record);
  useEffect(() => {
    setRecord(record);
  }, [record]);

  const isTabletorMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const { observations, is_public } = _record;
  const content =
    _record.id === -1 &&
      _record.foja_number !== null &&
      _record.foja_number !== ""
      ? observations
      : observations;
  return isTabletorMobile ? (
    <Popover
      content={content}
      style={{ display: "flex", alignItems: "center", gap: "1ch" }}
    >
      <span style={{ color: "#038DEF" }}>{`${content.substr(0, 50)}...`}</span>
      {!is_public && <CandadoGris height="20" />}
    </Popover>
  ) : (
    <div style={{ display: "flex", alignItems: "center", gap: "1ch" }}>
      {!is_public && <CandadoGris height="20" />}
      <ExpandCollpaseText
        copyFromText={true}
        onEndEditing={
          _record.id !== -1 && !_record.id_tramite_mev
            ? text => {
              updateMovementObservation(text, _record.id)
                .then(data => {
                  setRecord(data);
                })
                .catch(e => console.log(e));
            }
            : null
        }
        text={content}
      />
    </div>
  );
};
