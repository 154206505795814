import { ContactsOutlined, DownOutlined, FolderAddOutlined, QuestionOutlined, PlusOutlined, ScheduleOutlined } from '@ant-design/icons';
import { Button, Dropdown, Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import "./index.css";
import CreateExpedientModal from '../../modals/CreateExpedientModal';
import CreateClientModal from '../../modals/CreateClientModal';
import Draggable from 'react-draggable';
import { isMobile } from 'react-device-detect';
import { withRouter } from 'react-router-dom';
import { AppointmentModal } from '../../modals/CreateAppointmentModal';


const QuickActionsMenu = ({ history, location }) => {

  const [showCreateExpedient, setShowCreateExpedient] = useState(false);
  const [showAddContact, setShowAddContact] = useState(false);
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [dragging, setDragging] = useState(false);

  const [notDraggable, setNotDraggable] = useState(isMobile);

  //Ref de los botones desplegados por el menú rápido.
  const action1Ref = useRef();
  const action2Ref = useRef();
  const action3Ref = useRef();
  const action4Ref = useRef();

  const actions = [
    {
      ref: action1Ref,
      title: "Crear expediente",
      icon: (<FolderAddOutlined className="quick-act-icon" />),
      function: () => {
        setShowCreateExpedient(true)
        setShowAddContact(false)
        setShowAppointmentModal(false);
      }
    },
    {
      ref: action2Ref,
      title: "Añadir contacto",
      icon: (<ContactsOutlined className="quick-act-icon" />),
      function: () => {
        setShowAddContact(true)
        setShowCreateExpedient(false)
        setShowAppointmentModal(false);
      }
    },
    {
      ref: action4Ref,
      title: "Agendar Cita",
      icon: (<ScheduleOutlined className="quick-act-icon" />),
      function: () => {
        setShowAppointmentModal(true)
        setShowAddContact(false)
        setShowCreateExpedient(false)
      }
    },
    {
      ref: action3Ref,
      title: "FAQ",
      icon: (<QuestionOutlined className="quick-act-icon" />),
      function: () => {
        window.open('https://metajuridico.tawk.help/', '_blank');
      }
    }
  ];

  useEffect(() => {
    if (dragging) {
      draggableRef.current.style.opacity = "0.8";
    } else {
      draggableRef.current.style.opacity = "1";
    }
  }, [dragging])

  function useDragHandler(draggableRef, itemsRef, mobile) {
    useEffect(() => {
      const allReferences = [draggableRef, ...itemsRef];
      if (mobile) {

        function touchStartHandler(event) {
          const handleTouchOutside = allReferences.every(ref => ref.current && !ref.current.contains(event.target));
          if (
            handleTouchOutside
          ) {
            setShowDropdown(false);
          }
          event.preventDefault();
          let enablerDebouncedDrag;


          if (draggableRef.current && draggableRef.current.contains(event.target)) {
            enablerDebouncedDrag = setTimeout(() => setNotDraggable(false), 550)

          }
          document.addEventListener("touchend", (e) => {
            clearTimeout(enablerDebouncedDrag);
            setNotDraggable(true);
            setDragging(false);
          }, { once: true });
        }

        document.addEventListener("touchstart", touchStartHandler, { capture: true });

        return () => {
          document.removeEventListener("touchstart", touchStartHandler);
        };

      } else {

        function handleClickOutside(event) {
          const handleClickOutside = allReferences.every(ref => ref.current && !ref.current.contains(event.target));
          if (
            handleClickOutside
          ) {
            setShowDropdown(false);
          }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }
    }
      , [draggableRef]);
  }



  const draggableRef = useRef(null);
  useDragHandler(draggableRef, [action1Ref, action2Ref, action3Ref, action4Ref], isMobile);

  const draggableProps = isMobile ? {
    //Funciones que se ejecutan al hacer drag en touchScreen
    onDrag: (e, data) => {
      if (notDraggable) {
        return false
      }
      setDragging(true);
    }
  } : {
    //Funciones que se ejecutan al hacer drag en desktop
    onDrag: (e, data) => {
      setDragging(true)
    }
  };

  return (
    <>
      <CreateExpedientModal
        showModal={showCreateExpedient}
        onCancel={() => setShowCreateExpedient(false)}
      />
      <CreateClientModal
        title={"cliente"}
        visible={showAddContact}
        onNewClient={c => {
          setShowAddContact(false);
        }}
        onCancel={() => {
          setShowAddContact(false);
        }}
        showLinkButton={true}
      />
      <AppointmentModal
        visible={showAppointmentModal}
        onCancel={() => setShowAppointmentModal(false)}
        isOutOfShift={false}
        onDone={() => setShowAppointmentModal(false)}
      />
      <Draggable
        {...draggableProps}
        bounds="body"
        handle="#dragger-trigger"
        nodeRef={draggableRef}
      >
        <div
          className="quick-atc-menu"
          id="dragger-trigger"
          ref={draggableRef}
          onMouseUpCapture={(e) => {
            if (dragging) {
              setShowDropdown(showDropdown);
            } else {
              setShowDropdown(!showDropdown);
            }
            setDragging(false)
          }}
          onTouchEndCapture={(e) => {
            setTimeout(() => {
              if (dragging) {
                setShowDropdown(showDropdown);
              } else {
                setShowDropdown(!showDropdown);
              }
            }, 50)

          }}
        >
          <Dropdown
            trigger="click"
            placement="top"
            visible={!dragging && showDropdown}
            overlay={() => quickActions({ actions: actions })}
          >
            <Button
              size="large"
              className="quick-act-btn"
              shape='circle'
              color='blue'
            >
              {showDropdown ?
                <DownOutlined style={{ fontSize: '17px', lineHeight: '29px' }} /> :
                <PlusOutlined className="quick-act-icon" />
              }
            </Button>
          </Dropdown>
        </div>

      </Draggable>


    </>
  )
}

export default withRouter(QuickActionsMenu);

const quickActions = ({ actions }) => {

  return (
    <>
      {actions.map((action, i) => (
        <div style={{ margin: '5px' }} ref={action.ref} key={i.toString()} >
          <Tooltip
            placement="left"
            title={action.title}

          >
            <Button
              size="large"
              className="quick-act-btn"
              shape='circle'
              onClick={action.function}
              onTouchStartCapture={(e) => {
                action.function();
              }}>
              {action.icon}
            </Button>
          </Tooltip>
        </div>
      ))}
    </>

  )
}