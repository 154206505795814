import React, { useCallback, useContext } from 'react'
import { Button, Tag, Typography } from "antd"
import { TurnoSelectorContext } from './TurnoSelectorContext'
import { calculateMinuteSteps } from './TurnoSelectorContext'
import { hideBusySteps } from './TDateSelect'

export default function THourSelect() {

  const { setHour, hour, steps, availableHours, isOverturn, user, date, duration, busyHours } = useContext(TurnoSelectorContext)
  if (!steps || !user || !date) return <div />

  const _steps = useCallback(() => {
    let s = steps
    if (isOverturn) s = calculateMinuteSteps(duration)
    return hideBusySteps(busyHours, s)
  }, [steps, isOverturn, busyHours])

  return <div className='turno-selector-hours-container'>
    <div className='turno-selector-hours'>
      <div style={{ display: 'flex', columnGap: 5 }}>
        <Typography.Text>Selecciona la hora</Typography.Text>
        {isOverturn && <Tag color='red'>Sobreturno</Tag>}
      </div>
      {_steps()
        .filter(h => {
          return availableHours.includes(Number(h.split(":")[0]))
        })
        .map(step => {
          return <HourButton
            step={step}
            selected={hour === step}
            setHour={setHour}
            key={step}
          />
        })}
    </div>
  </div>
}

const HourButton = ({ step, selected, setHour }) => {
  return <Button
    onClick={() => setHour(step)}
    className='turno-hour-button' key={step}
    type={selected ? 'primary' : 'default'}>{step}</Button>
}