import React, { useEffect, useState } from "react";
import { LawTable } from "../../law-components/Table";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Modal, message, Tag } from "antd";
import CreateClientModal from "../../modals/CreateClientModal";
import StudioService from "../../services/studio.service";
import ViewClient from "./ViewClient";
import ClientService from "../../services/client.service";
import AssociatedExpedients from "./components/AssocatedExpedients";
import CallLog from "../../law-components/CallLog";
import { withRouter } from "react-router";
import { useMediaQuery } from "react-responsive"
import ClientListMobile from "./components/ClientList.mobile";
import { connect } from "react-redux";
import { downloadBlob } from "../ExpenseList";
import ExcelButton from "../../util/ExcelButton";
import PdfButton from "../../util/PdfButton";
import { useGAPageView } from "../../hooks/useGA";

const ClientPage = ({ history, currentLang }) => {

  const isClientPage = window.location.href.includes("client");
  useGAPageView()
  const [modalVisibility, showModal] = useState(false);
  const [newClient, setNewClient] = useState(null);
  const [currentClient, setCurrentClient] = useState(null);
  const [clientModal, showClientModal] = useState(false);
  const [clientToEdit, setClientToEdit] = useState(null);
  const [expedients, setExpedients] = useState([]);
  const withTutorial = history.location.state ? history.location.state.withTutorial : undefined;
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' });

  const [searchValue, setSearchValue] = useState(null);

  const [exportingXlsx, setExportingXlsx] = useState(false);
  const [exportingPdf, setExportingPdf] = useState(false);

  useEffect(() => {
    if (withTutorial) {
      showModal(true);
    }
  }, [withTutorial]);

  const editClient = client => {
    setClientToEdit(client);
    showModal(true);
  };
  console.log(isTabletOrMobile);
  return (
    <React.Fragment>
      <Modal
        footer={null}
        style={isTabletOrMobile ? { top: 0 } : undefined}
        width={isTabletOrMobile ? "100vw" : "75vw"}
        title="Expedientes asociados"
        visible={expedients.length > 0}
        onCancel={() => setExpedients([])}
      >
        <AssociatedExpedients expedients={expedients} />
      </Modal>
      <Modal
        title={`Información del ${isClientPage ? currentLang.text.client : "contacto"}`}
        visible={clientModal && currentClient}
        destroyOnClose
        width="75vw"
        closable
        footer={null}
        zIndex={1034}
        onCancel={() => {
          showClientModal(false);
          setCurrentClient(null);
        }}
      >
        <ViewClient currentLang={currentLang} client={currentClient} />
      </Modal>

      <CreateClientModal
        title={isClientPage ? currentLang.text.client : "Contacto"}
        visible={modalVisibility}
        client={clientToEdit}
        onNewClient={c => {
          setNewClient(c);
          showModal(false);
          if (withTutorial) {
            history.push("expedient/add", { withTutorial: true });
          }
        }}
        onUpdated={c => {
          setNewClient(c);
          showModal(false);
          setClientToEdit(null);
        }}
        onCancel={() => {
          showModal(false);
          setClientToEdit(null);
        }}
        showLinkButton={true}
      />

      {!isTabletOrMobile ?
        <LawTable
          getSearchParam={(value) => setSearchValue(value)}
          title="Contactos"
          onAddButtonClick={() => {
            showModal(true);
          }}
          adButtonSibling={
            <div style={{ display: "flex", gap: "5px", marginRight: "10px" }}>
              <ExcelButton
                loading={exportingXlsx}
                width={"32px"}
                height={"32px"}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setExportingXlsx(true);
                  StudioService.exportClientsAs({ pageSize: "no_limit", search: searchValue === '' ? null : searchValue }, "xlsx")
                    .then(data => {
                      downloadBlob(data, "listado_clientes.xlsx");
                    })
                    .catch(err => { })
                    .finally(() => {
                      setExportingXlsx(false);
                    });
                }}
              />
              <PdfButton
                loading={exportingPdf}
                width={"32px"}
                height={"32px"}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setExportingPdf(true);
                  StudioService.exportClientsAs({ pageSize: "no_limit", search: searchValue === '' ? null : searchValue }, "PDF")
                    .then(data => {
                      downloadBlob(data, "listado_clientes.pdf");
                    })
                    .catch(err => { })
                    .finally(() => {
                      setExportingPdf(false);
                    });
                }}
              />
            </div>
          }
          addButton={
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
            >
              Nuevo {isClientPage ? currentLang.text.client : "contacto"}
            </Button>
          }
          show={c => {
            setCurrentClient(c);
            showClientModal(true);
          }}
          createExpedient={c => history.push("/expedient/add", { client: c })}
          fetchExpedients={c => getAssociatedExpedients(c.id).then(setExpedients)}
          onSearch={ClientService.searchClient}
          reload={newClient}
          columns={clientCols}
          fetch={StudioService.getClientsPg}
          edit={c => editClient(c)}
          remove={c => {
            deleteClient(c)
              .then(client => setNewClient(client))
              .catch(e =>
                message.error(
                  `El ${currentLang.text.client} esta relacionado a uno o mas expedientes.`
                )
              );
          }}
        /> :
        <ClientListMobile
          fetchFunction={StudioService.getClientsPg}
          reload={newClient}
          showClient={c => {
            setCurrentClient(c);
            showClientModal(true);
          }}
          createClientModal={() => showModal(true)}
          editClientModal={c => editClient(c)}
          associatedExpedients={(c => getAssociatedExpedients(c.id).then(setExpedients))}
        />
      }
    </React.Fragment>
  );
};

export async function deleteClient(client) {
  const result = await ClientService.deleteClient(client);
  if (result.status !== 200) {
    throw new Error(`El cliente esta relacionado a uno o mas expedientes.`);
  } else if (result.status === 200) {
    return client;
  }
}

const clientCols = [
  {
    key: "firstname",
    dataIndex: "firstname",
    title: "Nombre y Apellido",
    width: 300,
    render: (f, record) => `${record.firstname || ""} ${record.lastname || ""}`
  },
  {
    key: "tags",
    dataIndex: "tags",
    title: "Tags",
    width: 200,
    align: "center",
    render: tags => {
      return tags.map(tag => <Tag color="#016ECE">{tag.name}</Tag>);
    }
  },
  {
    key: "identification_type",
    dataIndex: "identification_type",
    title: "Identificación",
    render: (identification_type, record) =>
      `${identification_type ? identification_type.code : ""} ${record.identification_number ? record.identification_number : ""
      }`
  },
  { key: "email", dataIndex: "email", title: "Correo" },
  { key: "phone", dataIndex: "mobile_phone", title: "Celular", align: "center" },
  {
    key: "calls", dataIndex: "id", title: "Llamadas", align: "center", render: (id, contact) => {
      return <CallLog contact={contact} asModal notify showExpedient size="middle" newCall={false} />
    }
  },
];

const mapStateToProps = ({ lang }) => {
  const { currentLang } = lang
  return { currentLang };
};

export default connect(mapStateToProps, {})(withRouter(ClientPage));

async function getAssociatedExpedients(id) {
  const response = await ClientService.getExpedients(id);
  return response.data;
}
