import { Button, Input, Popover, Progress } from "antd";
import React, { useState } from "react";
import './index.less';
import ExpedientMovementService from "../../services/expedient_movement.service";


const ObservationAutocomplete = ({initialValue, onChange}) => {
  const [typedObservation,setTypedObservation] = useState(initialValue ? initialValue : '')
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [observer,setObserver] = useState();

  const debouncedSearch = (word) => {
    clearTimeout(observer);
    if (word !== "" && word !== null && word !== undefined){
      setObserver(
        setTimeout(
          () => fetchObservations(word)
            .then((c) => {
              if (c){ 
                setData(c)
              }
            })
          , 1500))
    } else {
      setData([])
    }
  } 
 
  const fetchObservations = async word => {

      setLoading(true);
      const data = await ExpedientMovementService.getObservationsByStudio({search: word, limit: 5});
      let observations = data.data;
      setLoading(false);
      return observations;
    
  };

  return (
    <>
      <Input.TextArea  
        key="observations"
        value={typedObservation}
        className="responsive-input"
        onChange={ e => {
          setTypedObservation(e.currentTarget.value);
          onChange && onChange(e.currentTarget.value);
          debouncedSearch(e.currentTarget.value);
        }}
        />
        {loading && <Progress percent={99.9} showInfo={false} type="line" status="active" />}
      { data.length > 0 && !loading && data.map(m => (
        <Popover content={m.substr(0,300)}>
            <Button  
                
            onClick={ e => {
              setTypedObservation(m);
              onChange && onChange(m);
              setData([]);
              }} 
              block={true}
              className="completion-item">
                {m.substr(0, 120)}...
            </Button>
        </Popover>
      ))}
    </>
  );
};

export default ObservationAutocomplete;
