import React from 'react'
import { useMevIntegration } from '../../../routes/ConfigPage/ThirdPartyConfigPage/useMevIntegration';
import { Alert, Button, Form, Input, Tooltip, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import Flex from '../../../components/Flex';
import { parseJwt } from '../../../util/jwt.helper';
import TicketService from '../../../services/ticket.service';
import Swal from 'sweetalert2';

export function MevCausaInput({ getFieldDecorator, isFieldTouched, getFieldError, expedient, editing }) {

    const { mev: mevData, loading: loadingMev } = useMevIntegration();
    const { mev_user, mev_password } = mevData || { mev_user: null, mev_password: null }
    const { user } = parseJwt();
    const hasUser = mev_user && mev_password
    const hasUsesLeft = mevData && mevData.usage.used < mevData.usage.total
    const hasMev = user && user.studio && !!user.studio.mev_scraping

    if (loadingMev) return null

    if (!hasMev) return null

    if (user.studio && user.studio.is_trial) {
        return <Flex flexDirection='row' gap='1ch'>
            <Input disabled />
            <Button onClick={() => {
                Swal.fire({
                    icon: "info",
                    title: "Contenido Premium",
                    text: "Solo disponible en el plan Calculadoras y plan MEV!\n ¿Desea suscribirse al plan MEV?",
                    confirmButtonColor: "#038fde",
                    showCancelButton: true,
                    confirmButtonText: "Solicitar plan MEV",
                    cancelButtonText: "Cancelar"
                }).then(result => {
                    if (result.isConfirmed) {
                        try {
                            window.location.href = 'https://www.liquidacionesjuridicas.com/wp-content/themes/businex/scripts/boton_suscribite_trial.php';
                        } catch (error) {
                            Swal.fire({
                                icon: "error",
                                title: "Error",
                                text: "Ocurrio un error al enviar la solicitud",
                                confirmButtonColor: "#038fde"
                            });
                        }
                    }
                });
            }} type="primary" >Configurar MEV</Button>
        </Flex>
    }

    if (!hasUser) {
        return <Flex flexDirection='row' gap='1ch'>
            <Input disabled />
            <a href="/settings#integraciones">
                <Button type="primary" >Configurar MEV</Button>
            </a>
        </Flex>
    }

    if (!hasUsesLeft) {
        return <Form.Item labelCol={{ span: 24 }} key="mev_causa" label="Causa (MEV)">
            <Alert
                message="Limite MEV alcanzado"
                description={`Has alcanzado el limite de expedientes que puedes vincular con tu plan actual (${mevData.usage.total} vinculaciones.).`}
                type="info"
                showIcon
            />
        </Form.Item>
    }

    const mevCausaError = isFieldTouched("mev_causa") && getFieldError("mev_causa");

    return (
        <Form.Item
            labelCol={{ span: 24 }}
            key="mev_causa"
            label={<div><Typography.Text>
                Causa MEV{" "}
                <Tooltip title="Solo numero de causa MEV sin prefijo ni sufijo">
                    <InfoCircleOutlined />
                </Tooltip>
            </Typography.Text>
                <br />
            </div>
            }
            help={
                mevCausaError
                    ? "Ingrese solo números sin prefijo ni sufijo"
                    : `Vinculaciones MEV restantes: ${mevData ? mevData.usage.left : "..."
                    }`
            }
            validateStatus={mevCausaError ? "error" : "success"}
        >
            {getFieldDecorator("mev_causa", {
                initialValue: editing
                    ? expedient.mev_causa
                        ? expedient.mev_causa
                        : null
                    : null,
                rules: [
                    {
                        pattern: new RegExp(/^[0-9]+$/),
                        message: "Por favor, ingrese solo números."
                    }
                ]
            })(<Input className="responsive-input" />)}
        </Form.Item>
    )
}