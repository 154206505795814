import React, { useContext, useState } from 'react';
import { Input, Select } from "antd";
import { TurnoSelectorContext } from './TurnoSelectorContext';

const { Option } = Select

export function TDurationInput() {

  const { duration, setDuration } = useContext(TurnoSelectorContext)
  const [showCustom, setShowCustom] = useState(false)

  const handleSelect = (value) => {
    const numDuration = Number(value)
    if (!isNaN(numDuration)) {
      setDuration(numDuration)
      setShowCustom(false)
    } else {
      setShowCustom(true)
    }
  }

  return <>
    <Select className='responsive-input' onSelect={handleSelect} value={duration}>
      <Option value={15}>15</Option>
      <Option value={30}>30</Option>
      <Option value={60}>60</Option>
      <Option value={'custom'}>Personalizado</Option>
    </Select>
    {showCustom && <Input type='number' min={15} max={59} onChange={e => e.target.value && setDuration(Number(e.target.value))} />}
  </>
}