import axios from "../util/Api";

const ExpedientMovementService = {
  updateObservation: (observation, movement_id) => {
    return axios.post(
      `/expedient-movement/update-observations`,
      {
        observation,
        movement_id
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      }
    );
  },
  getConcernedMovements: user => {
    return axios.get(`/expedient/concerned_movements/${user}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  fetchMevMovements: (params, signal) => {
    console.log("fetchMevMovements", params, signal)
    return axios.get(`daily-mev`, {
      params,
      signal,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      }
    }).then((resp) => {
      return {
        ...resp,
        data: {
          ...resp.data,
          data: resp.data.data.map(mov => ({
            ...mov,
            responsible: mov.expedient.expedient_responsibles
          }))
        }
      }
    });
  },
  getExpedientMovements: async params => {
    const { data } = await axios.get(`/expedient-movements/by-expedient`, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
    return { data: data.map(movement => ({ ...movement, hasFiles: movement.files_count > 0 })) }
  },
  getExpedientMovementsPaginated: async params => {
    return axios.get(`/paginate/expedient-movements/by-expedient`, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  countExpedientMovements: expedient => {
    return axios.get(`/expedient-movement/expedient/count/${expedient}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  createExpedientMovement: (expedient_movement, get_progres) => {
    return axios.post(`/expedient-movement`, expedient_movement, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },
      onUploadProgress: progress => {
        get_progres(Math.trunc(getPercentage(progress.loaded, progress.total)));
      }
    });
  },
  updateExpedientMovement: (expedient_movement, get_progres) => {
    return axios.post(`/expedient-movement/update`, expedient_movement, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },
      onUploadProgress: progress => {
        get_progres(Math.trunc(getPercentage(progress.loaded, progress.total)));
      }
    });
  },
  deleteExpedientMovement: expedient_movement => {
    return axios.delete(`/expedient-movement/${expedient_movement}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getMovementTypeByExpedientType: expedient_type => {
    return axios.get(`/movement-type-by-expedient-type/${expedient_type}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  countPendingExpedientMovements: expedient => {
    return axios.get(`/expedient-movement/count-pending/${expedient}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getLastActionMovement: expedient => {
    return axios.get(`/expedient-movement/last-action/${expedient}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getExpedientMovementsByDateRange: (params, signal) => {
    return axios.get(`/expedient-movement/filter-by`, {
      params,
      signal,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  createExpedientMovementType: movement_type => {
    return axios.post("/movement-type", movement_type, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  editExpedientMovementType: movement_type => {
    return axios.patch(`/movement-type/${movement_type.id}`, movement_type, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  deleteExpedientMovementType: movement_type => {
    return axios.delete(`/movement-type/${movement_type}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getExpedientMovementTypePg: params => {
    return axios.get(`/paginate/movement-type`, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  fetchScrapingMovements: (params, signal) => {
    return axios.get("/daily-list", {
      params,
      signal,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  downloadScrapingFile: cuij => {
    return axios.get(`expedient-movement/scraping-pdf/${cuij}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  exportAs: (params) => {
    return axios
      .get("/export-csv-xls-movements", {
        params,
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      .then(
        resp =>
          new Blob([resp.data], {
            type:
              params.format === "csv"
                ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
                : "application/pdf"
          })
      );
  },
  getMovementTypeByExpedient: (type = "all") => {
    return axios.get("/movement-type", {
      params: {
        type
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  movementTypeUsedInExpedient: (expedient) => {
    return axios.get("/movement-type-used-in-expedient", {
      params: {
        expedient: expedient
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  toggleStatus: movement => {
    return axios.get(`/expedient-movement/toggle-status/${movement}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  togglePin: id => {
    return axios.get(`/expedient-movement/toggle-pin/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  postponeMovement: params => {
    return axios.post("/expedient-movement/postpone", params, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getFiles: movement => {
    return axios.get(`/expedient-movement/${movement}/files`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getObservationsByStudio: (params = {}) => {
    return axios.get("expedient/observations/studio", {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  massActionsOfExpedientMovements: data => {
    return axios.post("mass/actions/expedient-movements", data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  toggleScrapingInstaAccion: movement => {
    return axios.patch("scraping-toggle-action", movement, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  toggleMevScrapingInstaAccion: movement => {
    return axios.post("scraping-mev-toggle-action", movement, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  }
};

export default ExpedientMovementService;

export function getPercentage(x, y) {
  return (x / y) * 100;
}
