import React, { useState } from 'react';
import ExpedientMovementService from '../../services/expedient_movement.service';
import { Checkbox, message } from 'antd';

export const ToggleMovementStatusCheckComponent = ({ id, movement, setMovements, onChange, movements }) => {
    return id === -1 ||
        movement.movement_type_id ===
        Number.parseInt(
            process.env.REACT_APP_MOVEMENT_TYPE_ID_LEX_ESCRITO
        ) ? null : (
        <ToggleMovementStatusCheck
            short
            fullwidth
            disabled={id === -1}
            size="default"
            movement={movement}
            onUpdated={_movement => {
                const m = movements.map(m =>
                    m.id === _movement.id ? _movement : m
                );
                setMovements(m);
                onChange(_movement);
            }}
        />
    )
}

const ToggleMovementStatusCheck = ({
    onUpdated,
    movement,
    disabled,
    short
}) => {
    const [changingStatus, setChangeStatus] = useState(false);

    async function toggleStatus(id) {
        const status = await ExpedientMovementService.toggleStatus(id);
        return status.data;
    }
    return disabled ? null : (
        <Checkbox
            className="movement-status-checkbox"
            defaultChecked={movement.realizado}
            onChange={async () => {
                setChangeStatus(true);
                const { realizado } = await toggleStatus(movement.id);
                setChangeStatus(false);
                onUpdated({ ...movement, realizado });
                message.success("Se ha actualizado el estado del movimiento.");
            }}
        />
    );
};
