import React, { useState, useEffect } from "react";
import "./index.css";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import {
  FolderOpenOutlined,
  FolderOutlined,
  SearchOutlined
} from "@ant-design/icons";
import { Button, Table, Badge, Tag, Tooltip, Select, List, Input } from "antd";
import CreateExpedienteMovementModal from "../../modals/CreateExpedientMovementModal";
import ExpedientMovementService from "../../services/expedient_movement.service";
import moment from "moment";
import ExpedientFileList from "../ExpedientFiles/ExpedientFileList";
import { movementCounterUpdater } from "../../rxjsStore/movementCounterUpdater";
import { lastMovementUpdater } from "../../rxjsStore/lastMovementCounterUpdater";
import { authHeaders, baseURL } from "../../util/Api";
import { useMediaQuery } from "react-responsive";
import Authorization from "../../components/Authorization";
import MovementMassActionsForm from "./movementsMassActions";
import TogglePinMovement from "./PinMovement";
import { ReactComponent as DeleteIcon } from "../../assets/assets2_0/delete.svg";
import { ReactComponent as EditIcon } from "../../assets/assets2_0/edit.svg";
import { ReactComponent as AddButton } from "../../assets/assets2_0/add-button.svg";
import { ReactComponent as FilterAltIcon } from "../../assets/assets2_0/filter_alt.svg";
import { PlusCircleOutlined } from "@ant-design/icons";
import { ToggleMovementStatusCheckComponent } from "./ToggleMovementStatusCheck";
import { Exjudicial } from "./ExJudicial";
import { MovementObservations } from "./Observations";
import { MovementResponibles } from "./MovementResponsibles";
import { MovementActions } from "./MovementActions";
import {
  MobileTableContainer,
  MobileTableRow
} from "../../routes/ClientPage/components/ClientList.mobile";
import {
  ImportantFilter,
  JudicialFilter,
  MovementTypeFilter,
  StatusFilter
} from "./ExpedientMovementLocalFilters";
import {
  update_movements_filter,
  update_movements_search,
  toggle_show_export_expedient
} from "../../appRedux/actions/MovementsLocalFilters";
import { connect } from "react-redux";
import EmptyExpedientMovements from "./EmptyExpedientMovements";

const { Option } = Select;

const ExpedienteMovements = ({
  expedient,
  withTutorial,
  movementsLocalFiltersState,
  movementsSearch,
  toggle_show_export_expedient,
  update_movements_filter,
  update_movements_search
}) => {
  const [movements, setMovements] = useState([]);
  const [modalVisible, modalSetVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toEditMovement, setMovementToEdit] = useState(null);

  const [reload, setReload] = useState(false);

  const isTabletorMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const [hideFilters, setHideFilters] = useState(
    isTabletorMobile ? true : false
  );
  const [triggerSearch, setTriggerSearch] = useState(false);

  const [currentExpandedRows, setCurrentExpandedRows] = useState([]);
  const [selectedRowsId, setSelectedRowsId] = useState([]);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: false
  });

  useEffect(() => {
    fetchMovements();
    toggle_show_export_expedient(true);
    return () => { };
  }, [movementsLocalFiltersState, triggerSearch, reload]);

  useEffect(() => {
    if (withTutorial) {
      modalSetVisible(true);
    }
  }, [withTutorial]);

  const fetchMovements = (page) => {
    setLoading(true);
    ExpedientMovementService.getExpedientMovementsPaginated({
      page: page || 1,
      pageSize: isTabletorMobile ? 3 : 10,
      expedient: expedient.id,
      status: movementsLocalFiltersState.status,
      judicial: movementsLocalFiltersState.judicial,
      important: movementsLocalFiltersState.important,
      movement_type: movementsLocalFiltersState.movement_type,
      sortOrder: movementsLocalFiltersState.sortOrder,
      search:
        movementsSearch !== "" && movementsSearch !== null
          ? movementsSearch
          : null
    }).then(({ data }) => {
      const m = data.data.map(movement => ({ ...movement, hasFiles: movement.files_count > 0 }));
      setMovements(m);
      setPagination({
        ...pagination,
        total: data.total,
        pageSize: data.per_page,
        current: data.current_page,
        nextUrl: data.next_page_url,
        prevUrl: data.prev_page_url
      })
      setLoading(false);
    });
  };

  const HandlePageChange = (page, order) => {
    fetchMovements(page);
  };



  const onChange = m => {
    movementCounterUpdater.updateMovemetCounter(m);
    lastMovementUpdater.updatelastMovementCounter(m);
  };

  const columns = [
    {
      key: "id",
      dataIndex: "id",
      title: "",
      width: "2%",
      render: (id, movement) => {
        if (id === -1) {
          return <></>
        }
        return <ToggleMovementStatusCheckComponent
          id={id}
          movement={movement}
          movements={movements}
          onChange={onChange}
          setMovements={setMovements}
          key="toggle-status"
        />
      }
    },
    {
      dataIndex: "id",
      title: "Ex/Judicial",
      key: "exjudicial",
      align: "center",
      width: "8%",
      render: (id, movement) => <Exjudicial movement={movement} />
    },
    {
      key: "movement_type",
      title: "Tipo",
      dataIndex: "movement_type",
      width: "10%",
      render: movement_type => (
        <Badge
          text={<span className="scalable-text">{movement_type.name}</span>}
          status="processing"
          color={movement_type.color}
        />
      )
    },
    {
      key: "date",
      dataIndex: "date",
      width: "10%",
      title: (
        <span style={{ cursor: "pointer" }}>
          Fecha{" "}
          <span style={{ color: "#038FDE" }}>
            {movementsLocalFiltersState.sortOrder ? "▲" : "▼"}
          </span>
        </span>
      ),
      align: "center",
      onHeaderCell: column => {
        return {
          onClick: () => {
            /* setLoading(true); */
            if (column.key === "date") {
              update_movements_filter({
                ...movementsLocalFiltersState,
                sortOrder: !movementsLocalFiltersState.sortOrder
              });
            }
            /* setTimeout(setLoading); */
          }
        };
      },
      render: date => (
        <span className="scalable-text">{moment(date).format("L LT")}</span>
      )
    },
    {
      key: "foja_number",
      dataIndex: "foja_number",
      title: "Fs",
      width: "5%",
      render: foja_number => (foja_number ? <Tag>{foja_number}</Tag> : null)
    },
    {
      key: "observations",
      dataIndex: "observations",
      title: "Observaciones",
      width: "45%",
      render: (o, record) => (
        <MovementObservations
          reload={setReload}
          updateMovementObservation={updateMovementObservation}
          record={record}
        />
      )
    },
    {
      key: "responsible",
      dataIndex: "responsible",
      align: "center",
      width: "6%",
      title: <Tooltip title="Responsables">Resp.</Tooltip>,
      render: responsibles => {
        return <MovementResponibles responsibles={responsibles} />;
      }
    },
    {
      key: "actions",
      dataIndex: "id",
      title: "Acciones",
      width: "20px",
      render: (id, movement) => {
        return (
          <MovementActions
            deleteMovement={deleteMovement}
            id={id}
            movement={movement}
            movements={movements}
            lastMovementUpdater={lastMovementUpdater}
            modalSetVisible={modalSetVisible}
            onChange={onChange}
            reload={setReload}
            setMovementToEdit={setMovementToEdit}
            setMovements={setMovements}
            EditIcon={EditIcon}
            DeleteIcon={DeleteIcon}
            TogglePinMovement={TogglePinMovement}
            dateSortOrder={movementsLocalFiltersState.sortOrder}
            key="mactions"
          />
        );
      }
    }
  ];

  const MobileListItem = ({ movement }) => {
    return (
      <MobileTableContainer
        style={{
          width: "100%",
          border: "1.5px solid #CCCCCC",
          borderRadius: "14px",
          background: "#fff",
          position: "relative",
          margin: "1ch 0px 0.5ch 0px",
          display: "grid",
          gap: "0px 0px"
        }}
      >
        <MobileTableRow style={{ flexFlow: "column" }}>
          <td style={{ width: "45%" }}>
            <b>Ex/Judicial</b>
          </td>
          <td style={{ display: "flex", alignItems: "center", gap: "2ch" }}>
            <ToggleMovementStatusCheckComponent
              id={movement.id}
              movement={movement}
              movements={movements}
              onChange={onChange}
              setMovements={setMovements}
              key={`toggle-status${movement.id}`}
            />
            <Exjudicial movement={movement} />
          </td>
        </MobileTableRow>
        <MobileTableRow>
          <td>
            <b>Tipo</b>
          </td>
          <td>
            <Badge
              text={<span>{movement.movement_type.name}</span>}
              status="processing"
              color={movement.movement_type.color}
            />
          </td>
        </MobileTableRow>
        <MobileTableRow>
          <td>
            <b>Fecha</b>
          </td>
          <td>
            <span style={{ cursor: "pointer" }}>
              {moment(movement.date).format("DD/MM/YYYY")}
            </span>
          </td>
        </MobileTableRow>
        <MobileTableRow style={{ flexFlow: "column" }}>
          <td>
            <b>Observaciones</b>
          </td>
          <td>
            <MovementObservations
              reload={setReload}
              updateMovementObservation={updateMovementObservation}
              record={movement}
            />
          </td>
        </MobileTableRow>
        <MobileTableRow>
          <td>
            <b>Resp.</b>
          </td>
          <td>
            <MovementResponibles responsibles={movement.responsible} />
          </td>
        </MobileTableRow>
        <MobileTableRow style={{ borderBottom: "none" }}>
          <td style={{ width: "30%" }}>
            <b>Acciones</b>
          </td>
          <td
            style={{
              width: "70%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "1.55em"
            }}
          >
            <MovementActions
              deleteMovement={deleteMovement}
              id={movement.id}
              movement={movement}
              movements={movements}
              lastMovementUpdater={lastMovementUpdater}
              modalSetVisible={modalSetVisible}
              onChange={onChange}
              reload={setReload}
              setMovementToEdit={setMovementToEdit}
              setMovements={setMovements}
              EditIcon={EditIcon}
              DeleteIcon={DeleteIcon}
              TogglePinMovement={TogglePinMovement}
              dateSortOrder={movementsLocalFiltersState.sortOrder}
              key="mactions"
            />
          </td>
        </MobileTableRow>
      </MobileTableContainer>
    );
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowsId(selectedRowKeys.map(selectedRowKey => selectedRowKey));
    },
    getCheckboxProps: record => ({
      style: Boolean(record.id_tramite_mev) || record.id === -1 ||
        record.movement_type_id ===
        Number.parseInt(process.env.REACT_APP_MOVEMENT_TYPE_ID_LEX_ESCRITO) ? { display: "none" } : {},
      disabled:
        Boolean(record.id_tramite_mev) || record.id === -1 ||
        record.movement_type_id ===
        Number.parseInt(process.env.REACT_APP_MOVEMENT_TYPE_ID_LEX_ESCRITO), // Column configuration not to be checked
      name: record.name
    })
  };

  movements.forEach((element, i) => {
    if (
      element.id === -1 ||
      element.movement_type_id ===
      Number.parseInt(process.env.REACT_APP_MOVEMENT_TYPE_ID_LEX_ESCRITO)
    ) {
      element.key = i;
    } else {
      element.key = element.id;
    }
  });

  return (
    <div>
      <div>
        {selectedRowsId.length > 0 && (
          <Authorization
            permission="expedient.update"
            onRender={() => (
              <MovementMassActionsForm
                selectedRowsId={selectedRowsId}
                reload={() => setReload(state => !state)}
              />
            )}
          />
        )}
      </div>
      <React.Fragment>
        <CreateExpedienteMovementModal
          onFileUpload={f => {
            const _m = movements.map(m =>
              m.id === f.pivot.movement.id
                ? { ...f.pivot.movement, hasFiles: true }
                : m
            );
            setReload(!reload)
          }}
          onCancel={() => {
            modalSetVisible(false);
            setMovementToEdit(null);
          }}
          finish={() => {
            modalSetVisible(false);
            setMovementToEdit(null);
          }}
          expedient={expedient}
          visible={modalVisible}
          expedient_movement={toEditMovement}
          onSaved={(movement, attach) => {
            onChange(movement);
            const m = [movement, ...movements].sort(
              (a, b) => moment(b.date) - moment(a.date)
            );
            setReload(!reload)
            !attach && modalSetVisible(false);
            !attach && setMovementToEdit(null);
            setReload(state => !state);
          }}
          onUpdated={(_movement, attach) => {
            onChange(_movement);
            const updated = movements.map(m =>
              m.id === _movement.id ? _movement : m
            );
            setReload(!reload)
            !attach && modalSetVisible(false);
            !attach && setMovementToEdit(null);
            setReload(state => !state);
          }}
        />
        <div>
          {isTabletorMobile && (
            <div
              style={{
                display: "flex",
                flexFlow: "row",
                gap: "1ch",
                alignItems: "center",
                marginBottom: "1ch"
              }}
            >
              <Button
                icon={<FilterAltIcon fill="#038fde" />}
                style={{ height: "45px" }}
                onClick={() => {
                  setHideFilters(!hideFilters);
                }}
              >
                Filtros
              </Button>
              <AddButton
                width="40"
                heigth="58"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  modalSetVisible(true);
                  setMovementToEdit(null);
                }}
              />
            </div>
          )}

          {!hideFilters && (
            <div
              style={{
                display: "flex",
                flexFlow: isTabletorMobile ? "column" : "row",
                gap: "1ch",
                alignItems: isTabletorMobile ? "flex-start" : "center"
              }}
            >
              <StatusFilter
                value={movementsLocalFiltersState.status}
                onSelect={v =>
                  update_movements_filter({
                    ...movementsLocalFiltersState,
                    status: v
                  })
                }
              />
              <JudicialFilter
                value={movementsLocalFiltersState.judicial}
                onSelect={v =>
                  update_movements_filter({
                    ...movementsLocalFiltersState,
                    judicial: v
                  })
                }
              />
              <ImportantFilter
                value={movementsLocalFiltersState.important}
                onSelect={v =>
                  update_movements_filter({
                    ...movementsLocalFiltersState,
                    important: v
                  })
                }
              />
              <MovementTypeFilter
                containerStyle={{
                  width: isTabletorMobile ? "100%" : "22%",
                  margin: "0px"
                }}
                allowNull={true}
                noLabel={true}
                onChange={mt => {
                  update_movements_filter({
                    ...movementsLocalFiltersState,
                    movement_type: mt ? mt.id : null
                  });
                }}
                expedient={expedient ? expedient.id : null}
                currentMovementType={movementsLocalFiltersState.movement_type}
              />
              <Input
                autoComplete="cc-csc"
                suffix={
                  <SearchOutlined
                    style={{ color: "#016ece", fontSize: "1.5em" }}
                  />
                }
                className="responsive-input"
                size="large"
                value={movementsSearch}
                placeholder={`Buscar movimientos (observaciones)`}
                onChange={({ target }) => update_movements_search(target.value)}
                style={{ width: isTabletorMobile ? "100%" : "28%" }}
                onKeyUp={e =>
                  e.key === "Enter" && setTriggerSearch(!triggerSearch)
                }
              />
              {!isTabletorMobile && (
                <Tooltip title="Crear movimiento">
                  <Button
                    // width="40"
                    // heigth="58"
                    // style={{ cursor: "pointer" }}
                    onClick={() => {
                      modalSetVisible(true);
                      setMovementToEdit(null);
                    }}
                    type="primary"
                    icon={<PlusCircleOutlined />}
                    size="large"
                  />
                </Tooltip>
              )}
              {/* <VideoTutorialButton hideTooltip tutorial={"lotes_movimientos"} /> */}
            </div>
          )}
        </div>
        {isTabletorMobile ? (
          <List
            loading={loading}
            pagination={{
              ...pagination,
              onChange: (page, pageSize) => {
                window.scroll({
                  top: 400,
                  behavior: "smooth"
                });
                HandlePageChange(page)
              }
            }}
            dataSource={loading ? [] : movements}
            renderItem={m => <MobileListItem key={m.id} movement={m} />}
            locale={{
              emptyText: (
                <EmptyExpedientMovements
                  AddNew={() => {
                    modalSetVisible(true);
                    setMovementToEdit(null);
                  }}
                />
              )
            }}
          />
        ) : (
          <Table
            key="mov-table"
            rowKey={"key"}
            pagination={{
              ...pagination,
              onChange: (page, pageSize) => {
                HandlePageChange(page)
              }
            }}
            rowSelection={{
              type: "checkbox",
              ...rowSelection
            }}
            size="large"
            className="gx-table-responsive"
            loading={loading}
            dataSource={movements}
            columns={columns}
            style={{ borderCollapse: "collapse" }}
            expandIcon={props => customExpandIcon(props)}
            rowClassName={record => {
              let classNames = "";
              classNames += (record.hasFiles || !!record.id_tramite_mev) ? "show " : "hidden ";
              classNames += record.id === -1 ? "external" : "internal";
              return classNames;
            }}
            locale={{
              emptyText: (
                <EmptyExpedientMovements
                  AddNew={() => {
                    modalSetVisible(true);
                    setMovementToEdit(null);
                  }}
                />
              )
            }}
            expandable={{
              expandIconColumnIndex: 9,
              indentSize: 1,
              expandIcon: ({ expanded, onExpand, record }) =>
                (record.hasFiles && !record.id_tramite_mev) ? (
                  expanded ? (
                    <Button
                      type="primary"
                      onClick={e => onExpand(record, e)}
                      icon={<FolderOpenOutlined />}
                      size="small"
                    />
                  ) : (
                    <Tooltip trigger="hover" title="Ver adjuntos">
                      <Button
                        type="primary"
                        onClick={e => onExpand(record, e)}
                        icon={<FolderOutlined />}
                        size="small"
                      />
                    </Tooltip>
                  )
                ) : null
            }}
            onExpandedRowsChange={setCurrentExpandedRows}
            expandedRowKeys={currentExpandedRows}
            expandedRowRender={(record, i) => (
              <ExpedientFileList
                showFilters={false}
                allowDelete={!record.id_tramite_mev}
                onAllFilesDeleted={() => {
                  setReload(!reload)
                  setCurrentExpandedRows(
                    currentExpandedRows.filter(cer => cer !== i)
                  );
                }}
                showUploader={false}
                service={ExpedientMovementService}
                expedient={record}
                showActions
              />
            )}
          />
        )}
      </React.Fragment>
    </div>
  );
};

export function customExpandIcon(props) {
  return (
    <span
      style={{ color: "#038fde", cursor: "pointer" }}
      onClick={e => {
        props.onExpand(props.record, e);
      }}
    >
      <LegacyIcon type={props.expanded ? "folder-open" : "folder"} />
    </span>
  );
}

const updateMovementObservation = async (observation, movement_id) => {
  const updated = await ExpedientMovementService.updateObservation(
    observation,
    movement_id
  );
  return updated.data;
};

const deleteMovement = async movement => {
  const deletedMovement = await ExpedientMovementService.deleteExpedientMovement(
    movement
  );
  return deletedMovement.data;
};

export const downloadPdf = async (href, name, format = "pdf") => {
  await fetch(href, {
    method: "GET",
    headers: new Headers({
      responseType: "blob",
      Authorization: authHeaders().Authorization
    })
  })
    .then(response => response.blob())
    .then(blob => {
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = `${name}.pdf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
};

export const downloadFileFunc = async (href, name) => {
  const response = await fetch(href, {
    method: "GET",
    headers: new Headers({
      responseType: "blob",
      Authorization: `Bearer ${localStorage.getItem("token")}`
    })
  });
  const blob = await response.blob();
  var url = window.URL.createObjectURL(blob);
  var a = document.createElement("a");
  a.href = url;
  if (name) {
    a.download = name;
  }
  document.body.appendChild(a);
  a.click();
  a.remove();
};

const mapStateToProps = ({ movementlocalFilters }) => {
  const { movementsLocalFiltersState, movementsSearch } = movementlocalFilters;
  return {
    movementsLocalFiltersState,
    movementsSearch
  };
};

export default connect(mapStateToProps, {
  update_movements_filter,
  update_movements_search,
  toggle_show_export_expedient
})(ExpedienteMovements);
