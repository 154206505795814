import React, { Component } from "react";
import { connect } from "react-redux";
import URLSearchParams from 'url-search-params'
import { Redirect, Route, Switch } from "react-router-dom";
import { ConfigProvider, message } from 'antd';
import { IntlProvider } from "react-intl";

import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import ForgotPassword from "../ForgotPassword";
import ResetPassword from "../ResetPassword";
import CreateAppointmentFrame from "../../routes/FramePage";
import WrappedUploadDocumentsPage from "../../routes/UploadDocumentsPage";
import { setInitUrl } from "appRedux/actions/Auth";
import { onLayoutTypeChange, onNavStyleChange, setThemeType } from "appRedux/actions/Setting";
import { handleDesignModeChange } from "../../appRedux/actions/DesignMode";
import { handleLangChange } from '../../appRedux/actions/Lang'
import axios from 'util/Api';

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK
} from "../../constants/ThemeSetting";
import { getUser } from "../../appRedux/actions/Auth";
import RegisterWithInvitationPage from "../../routes/InvitationPage/RegisterWithInvitationPage";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import CreateTrialStudio from "../Frames/CreateTrialStudio";
import Scan from "../../routes/Scan";
import SignPage from "../../routes/MetaSign/SignPage";
import AlreadySigned from "../../routes/MetaSign/AlreadySigned";
import { ExternalExpedientView } from "../Frames/ExternalExpedientView";
import VersionUpdater from "../../components/VersionUpdater/VersionUpdater";
import AltaStudio from "../../routes/AltaMpForm/AltaStudio";
import PlanUpgrade from "../../routes/PlanUpgrade";
import AltaButton from "../../components/AltaButton/AltaButton";
import HandleThirdPartyLoginCallback from "../../routes/HandleThirdPartyLoginCallback/HandleThirdPartyLoginCallback";

const RestrictedRoute = ({ component: Component, token, ...rest }) =>
  <Route
    {...rest}
    render={props =>
      token
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: { from: props.location }
          }}
        />}
  />;


class App extends Component {

  setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  setNavStyle = (navStyle) => {
    if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER) {
      document.body.classList.add('full-scroll');
      document.body.classList.add('horizontal-layout');
    } else {
      document.body.classList.remove('full-scroll');
      document.body.classList.remove('horizontal-layout');
    }
  };

  UNSAFE_componentWillMount() {

    if (this.props.token) {
      axios.defaults.headers.common['Authorization'] = "Bearer " + this.props.token;
      this.props.getUser()
    }

    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
    const params = new URLSearchParams(this.props.location.search);
    if (params.has("theme")) {
      this.props.setThemeType(params.get('theme'));
    }

    if (this.props.history.location.pathname === "/signin") {
      if (params.has("institution") && params.get("institution") === "mpdm") {
        this.props.handleDesignModeChange(true)
        //this.props.handleLangChange('mpdm')
      } else {
        this.props.handleDesignModeChange(false)
      }
    }

    if (params.has("nav-style")) {
      this.props.onNavStyleChange(params.get('nav-style'));
    }
    if (params.has("layout-type")) {
      this.props.onLayoutTypeChange(params.get('layout-type'));
    }
  }

  render() {
    const { match, location, themeType, layoutType, navStyle, locale, token, initURL } = this.props;
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add('dark-theme');
    }

    if (location.pathname === '/') {
      if (token === null) {
        return (<Redirect to={'/signin'} />);
      } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
        return (<Redirect to={'/'} />);
      } else {
        return (<Redirect to={initURL} />);
      }
    }

    if (location.pathname === '/forgot-password') {
      if (token != null) {
        return (<Redirect to={initURL} />);
      }
    }



    if (location.pathname.startsWith('/reset-password')) {
      if (token != null) {
        return (<Redirect to={initURL} />);
      }
    }

    this.setLayoutType(layoutType);

    this.setNavStyle(navStyle);

    const currentAppLocale = AppLocale[locale.locale];

    //ANTD GLOBAL CONFIG

    message.config({
      maxCount: 3
    });

    return (
      <ConfigProvider locale={currentAppLocale.antd}>
        <VersionUpdater />
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}>
          <Switch>
            <Route exact path='/trial' component={CreateTrialStudio} />
            <Route exact path='/scan' component={Scan} />
            <Route exact path='/signin' component={SignIn} />
            <Route exact path='/alta-estudio' component={AltaStudio} />
            <Route exact path='/signin-redirect' component={HandleThirdPartyLoginCallback} />
            <Route exact path='/forgot-password/' component={ForgotPassword} />
            <Route exact path='/reset-password/:email/:token' component={ResetPassword} />
            <Route exact path='/register-with-invitation' component={RegisterWithInvitationPage} />
            <Route exact path='/set-appointment/:user' component={CreateAppointmentFrame} />
            <Route exact path='/appointment/:appointment' component={WrappedUploadDocumentsPage} />
            <Route exact path={`/metasign/signature-request`} component={SignPage} />
            <Route exact path={`/metasign/mobile/signature-request`} component={SignPage} />
            <Route exact path={`/metasign/done`} component={AlreadySigned} />
            <Route exact path='/consulta-expediente' component={ExternalExpedientView} />
            <Route exact path='/plan-upgrade' component={PlanUpgrade} />
            <RestrictedRoute path={`${match.url}`} token={token} component={MainApp} />
          </Switch>
        </IntlProvider>
      </ConfigProvider>
    );
  }
}

const mapStateToProps = ({ settings, auth, designMode }) => {
  const { locale, navStyle, themeType, layoutType } = settings;
  const { authUser, token, initURL } = auth;
  const { isDefensoriaDesign } = designMode;
  return { locale, token, navStyle, themeType, layoutType, authUser, initURL, isDefensoriaDesign }
};
export default connect(mapStateToProps, { setInitUrl, getUser, setThemeType, onNavStyleChange, onLayoutTypeChange, handleDesignModeChange, handleLangChange })(App);
