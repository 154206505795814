import React, { createContext, useState } from "react"

export const turnoDefaultHours = [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]

export const TurnoSelectorContext = createContext({
  customTitle: '',
  date: [],
  availableDays: [],
  availableHours: [],
  busyHours: [],
  hour: undefined,
  user: undefined,
  client: undefined,
  observations: '',
  duration: 30,
  isOverturn: false,
  steps: calculateMinuteSteps(),
  setCustomTitle: (customTitle) => {
    this.customTitle = customTitle
  },
  setBusyHours: () => { },
  setUser(user) {
    this.user = user
  },
  setClient(client) {
    this.client = client
  },
  setObservations(observations) {
    this.observations = observations
  },
  setDate(date) {
    this.date = date
  },
  setHour(hour) {
    this.hour = hour
  },
  setDuration(d) {
    this.duration = d
    this.steps = calculateMinuteSteps(d, this.hours)
  },
  setAvailableDays(availableDays) {
    this.availableDays = availableDays
  },
  setAvailableHours(availableHours) {
    this.availableHours = availableHours
  },
  setSteps(steps) {
    this.steps = steps
  },
  setIsOverturn: () => { }
})

export function calculateMinuteSteps(duration = 30, hours = turnoDefaultHours) {
  let n = 0
  let steps = []
  let h = 0
  let loopLimit = 2000
  while (true) {
    if (steps.length > loopLimit) break;
    if (n >= 60) {
      n -= 60
      h += 1
    }
    if (steps.includes(n) || h >= hours.length - 1) break;
    steps.push(`${hours[h]}:${n.toString().padStart(2, '0')}`)
    n += duration
  }
  return steps
}

export const TurnoSelectorProvider = ({ children }) => {

  const [customTitle, setCustomTitle] = useState(null);
  const [date, setDate] = useState()
  const [hour, setHour] = useState()
  const [duration, setDuration] = useState(30)
  const [steps, setSteps] = useState(calculateMinuteSteps())
  const [user, setUser] = useState()
  const [client, setClient] = useState()
  const [observations, setObservations] = useState('')
  const [availableDays, setAvailableDays] = useState([])
  const [availableHours, setAvailableHours] = useState([7, 22])
  const [busyHours, setBusyHours] = useState([])
  const [isOverturn, setIsOverturn] = useState(false)

  const handleDurationChange = (d) => {
    setDuration(d)
    setSteps(calculateMinuteSteps(d))
  }

  const handleAvailableHoursChange = ([start, end]) => {
    const hours = []
    for (let i = start; i <= end; i++) {
      hours.push(i)
    }
    setAvailableHours(hours)
  }

  const handleDateChange = (d) => {
    setDate(d)
  }

  return <TurnoSelectorContext.Provider value={{
    customTitle,
    date,
    hour,
    duration,
    steps,
    user,
    client,
    observations,
    availableDays,
    availableHours,
    busyHours,
    isOverturn,
    setCustomTitle,
    setIsOverturn,
    setBusyHours,
    setUser,
    setClient,
    setObservations,
    setSteps,
    setDate: handleDateChange,
    setDuration: handleDurationChange,
    setHour,
    setAvailableDays,
    setAvailableHours: handleAvailableHoursChange
  }}>
    {children}
  </TurnoSelectorContext.Provider>
}
