import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import DeleteMovementConfirm from "../components/DeleteMovementConfirm";
import PostponeConfirm from "../components/PostponeConfirm";
import { ReactComponent as MovementTypeArrowIcon } from "../../../assets/assets2_0/movement_type_arrow.svg";
import { ReactComponent as EditIcon } from "../../../assets/assets2_0/edit.svg";
import { ReactComponent as TagIcon } from "../../../assets/assets2_0/tag.svg";
import { ReactComponent as CandadoBlanco } from "../../../assets/assets2_0/candado_blanco.svg";
import { ReactComponent as ExpedientIcon } from "../../../assets/assets2_0/copy.svg";
import { ReactComponent as EyeIcon } from "../../../assets/assets2_0/eye.svg";
import TogglePinMovement from "../../ExpedientMovements/PinMovement";
import styled from "styled-components";
import ToggleInstaAccionScraping, { ToggleInstaAccionMevScraping } from "../components/ToggleInstaAccionScraping";
import { downloadPdf } from "../../ExpedientMovements";
import { baseURL } from "../../../util/Api";
import ExpandCollpaseText from "../../ExpandCollapseText";
import { Link } from "react-router-dom";
import { LazyExpedientInfo } from "../../ExpedientInfo/LazyExpedientInfo";
import { Tag, Typography, Button, Tooltip } from "antd";
import ExpedientMovementService from "../../../services/expedient_movement.service";
import { ToggleMovementStatus } from "../SmallMovementCalendar";
import { DownloadOutlined, PlusCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import CreateExpedienteMovementModal from "../../../modals/CreateExpedientMovementModal";
import ExpedientResponsibles from "../../ExpedientResponsibles";
import PreviewFileModal from "../../../modals/PreviewFileModal";

const MovementItem = ({
  item,
  onSelect,
  onEditMovement,
  onUpdated,
  onPostponed,
  onDelete
}) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const [isCardHovered, setIsCardHovered] = useState(false);

  return (
    <div
      style={{
        width: "100%",
        border: isCardHovered ? "1px solid #8987C0" : "1px solid #CCCCCC",
        borderRadius: `${isTabletOrMobile ? "14px" : "4px"} 14px 14px 4px`,
        background: "#fff",
        position: "relative",
        margin: "1ch 0px 0.5ch 0px"
      }}
      onMouseEnter={() => setIsCardHovered(true)}
      onMouseLeave={() => setIsCardHovered(false)}
    >
      <ColorBorder
        position={isTabletOrMobile ? "bottom" : "top"}
        color={item.expedient.court ? item.expedient.court.color : "#fff"}
      />
      {isTabletOrMobile ? (
        <ExpedientNumberCornerLeft>
          <MovementNumberLink movement={item} />
        </ExpedientNumberCornerLeft>
      ) : (
        <ExpedientNumberCornerRight>
          <MovementNumberLink movement={item} />
        </ExpedientNumberCornerRight>
      )}
      <div className="movement-item-container">
        <div>
          <div
            style={{
              display: "flex",
              gap: "1ch",
              flexFlow: isTabletOrMobile ? "column" : "row"
            }}
          >
            <Typography.Text style={{ fontWeight: "bold", fontSize: 13 }}>
              {item.expedient.front}
            </Typography.Text>
            <ImportantAndJudicialTag movement={item} />
          </div>
          <div style={{ display: "flex", gap: "1ch" }}>
            <ExpandCollpaseText
              rows={2}
              copyFromText={true}
              text={
                item.id === -1 &&
                  item.foja_number !== null &&
                  item.foja_number !== ""
                  ? `fs ${item.foja_number} ${item.observations}`
                  : item.observations
              }
            />
          </div>
        </div>
        <div className="movement-item-status-container">
          <ExpedientResponsibles
            withLabel
            justifyContent="start"
            exp_responsibles={item.responsible}
          />
          {item.id === -1 ||
            item.movement_type_id ===
            Number.parseInt(
              process.env.REACT_APP_MOVEMENT_TYPE_ID_LEX_ESCRITO
            ) ? null : (
            <ToggleMovementStatus
              size="small"
              onUpdated={onUpdated}
              movement={item}
            />
          )}
          <div className="movement-item-status-subcontainer">
            <div
              style={{ display: "flex", alignItems: "center", gap: "0.6ch" }}
            >
              <MovementTypeArrowIcon height="14.5" />
              <Typography.Text style={{ fontSize: "1.1em", fontWeight: "500" }}>
                {item.movement_type.name}
              </Typography.Text>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", gap: "0.6ch" }}
            >
              <Typography.Text>
                {" "}
                <span>🗓</span> {moment(item.date).format("DD/MM/YYYY")}
              </Typography.Text>
              <Typography.Text>
                {" "}
                <span>🕒</span> {moment(item.date).format("hh:mm A")}
              </Typography.Text>
            </div>
          </div>
        </div>
        <CourtInfo movement={item} />
      </div>
      <div className="movement-actions-container">
        <MovementActions
          onPostponed={onPostponed}
          onDelete={onDelete}
          onSelect={onSelect}
          onEdit={onEditMovement}
          isTabletOrMobile={isTabletOrMobile}
          movement={item}
        />
      </div>
    </div>
  );
};

export const CourtInfo = ({ movement }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "0.7ch" }}>
      <Typography.Text style={{ fontWeight: "600", fontSize: "0.8em" }}>
        {movement.expedient.jurisdiction && (
          <span>
            {" "}
            <TagIcon width="9" height="9" />{" "}
            {movement.expedient.jurisdiction.name}
          </span>
        )}
      </Typography.Text>
      <Typography.Text style={{ fontWeight: "600", fontSize: "0.8em" }}>
        {movement.expedient.fuero && (
          <span>
            {" "}
            <TagIcon width="9" height="9" /> {movement.expedient.fuero.name}
          </span>
        )}
      </Typography.Text>
      <Typography.Text style={{ fontWeight: "600", fontSize: "0.8em" }}>
        {movement.expedient.court && (
          <span>
            {" "}
            <TagIcon width="9" height="9" /> {movement.expedient.court.name}
          </span>
        )}
      </Typography.Text>
    </div>
  );
};

const MovementNumberLink = ({ movement }) => {
  return (
    <Link to={`/expedient/details#${movement.expedient.uuid}`}>
      <LazyExpedientInfo uuid={movement.expedient.uuid}>
        <Typography.Text
          ellipsis
          style={{ fontSize: ".7rem", color: "#24335D" }}
        >
          {movement.id === -1 ? (
            <span>
              {movement.expedient.expedient_number}(
              {movement.header ? movement.header.radicacion : ""}-
              {movement.header ? movement.header.codigo_interno : ""})
            </span>
          ) : movement.expedient.header ? (
            <span>
              {movement.expedient.expedient_number}(
              {movement.expedient.header.radicacion}-
              {movement.expedient.header.codigo_interno})
            </span>
          ) : (
            movement.expedient.expedient_number || "Sin numero"
          )}
        </Typography.Text>
      </LazyExpedientInfo>
    </Link>
  );
};

export const ExpedientNumberCornerRight = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 5px;
  border-bottom: 1px solid rgb(204, 204, 204);
  text-decoration: underline;
  font-weight: 600;
  border-left: 1px solid #cccccc;
  border-radius: 0px 0px 0px 8px;
  z-index: 20;
`;

export const ExpedientNumberCornerLeft = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  padding: 5px;
  border-bottom: 1px solid rgb(204, 204, 204);
  text-decoration: underline;
  font-weight: 600;
  border-right: 1px solid #cccccc;
  border-radius: 0px 0px 8px 0px;
  z-index: 20;
`;

export const ColorBorder = ({ color, position }) => {
  const mobilePos = position === "bottom";

  return (
    <div
      style={{
        width: mobilePos ? "100%" : "7px",
        height: mobilePos ? "7px" : "100%",
        position: "absolute",
        left: "0px",
        [position]: "0px",
        background: color || "#d6d4d0",
        borderRadius: "4px"
      }}
    />
  );
};

const ImportantAndJudicialTag = ({ movement }) => {
  return (
    <div className="import-judicial-tag">
      {movement.important ? (
        <Tag
          color="#8CB3FF"
          style={{ fontSize: ".65em", margin: "0.5px", height: "fit-content" }}
        >
          Importante
        </Tag>
      ) : (
        ""
      )}

      {movement.movement_type_id !==
        Number.parseInt(process.env.REACT_APP_MOVEMENT_TYPE_ID_LEX_ESCRITO) && (
          <Tag
            color={movement.is_judicial ? "#8987C0" : "#DDB925"}
            style={{ fontSize: ".65rem", margin: "0.5px", height: "fit-content" }}
          >
            {movement.is_judicial ? "Judicial" : "Extrajudicial"}
          </Tag>
        )}

      {!movement.is_public ? (
        <Tag
          icon={<CandadoBlanco height="10" />}
          color="#2180d6"
          style={{ fontSize: ".65em", margin: "0.5px", height: "fit-content" }}
        >
          {" "}
          Privado
        </Tag>
      ) : (
        ""
      )}
    </div>
  );
};

const MovementActions = ({
  movement,
  onPostponed,
  onDelete,
  onSelect,
  onEdit,
  isTabletOrMobile
}) => {
  const [modalVisible, modalSetVisible] = useState(false);
  const [toEditMovement, setMovementToEdit] = useState(null);
  // const [expedient, setExpedient] = useState(null);

  const [isEditHovered, setIsEditHovered] = useState(false);
  const [isAddHovered, setIsAddHovered] = useState(false);
  const [isExpedientHovered, setIsExpedientHovered] = useState(false);
  const [isDownloadHovered, setIsDownloadHovered] = useState(false);
  const [isPreviewHovered, setIsPreviewHovered] = useState(false);

  const [previewFile, setPreviewFile] = useState(false);
  const [fileToPreview, setFileToPreview] = useState(undefined);

  const [loading, setLoading] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        gap: isTabletOrMobile ? "1ch" : "2ch",
        alignItems: "center"
      }}
    >
      {fileToPreview && <PreviewFileModal
        showModal={previewFile}
        setShowModal={setPreviewFile}
        file={fileToPreview}
        setFileToPreview={setFileToPreview}
      />}
      {/* {<Authorization permission="expedient.update" />} */}
      {
        <>
          <CreateExpedienteMovementModal
            onCancel={() => {
              modalSetVisible(false);
              setMovementToEdit(null);
            }}
            finish={m => {
              onPostponed(m);
              modalSetVisible(false);
              setMovementToEdit(null);
            }}
            expedient={movement.expedient}
            visible={modalVisible}
            onSaved={movement => {
              modalSetVisible(false);
              setMovementToEdit(null);
            }}
          />
          <Tooltip trigger="hover" title="Crear nuevo movimiento">
            <PlusCircleOutlined
              width={"15.8"}
              style={{
                cursor: "pointer",
                color: isAddHovered ? "#0185F8" : "#002A4E"
              }}
              onClick={() => {
                modalSetVisible(true);
                setMovementToEdit(null);
              }}
              onMouseEnter={() => setIsAddHovered(true)}
              onMouseLeave={() => setIsAddHovered(false)}
            />
          </Tooltip>
          <Link
            to={`/expedient/details#${movement.expedient.uuid}`}
            style={{ display: "flex" }}
          >
            <Tooltip trigger="hover" title="Entrar al expediente">
              <ExpedientIcon
                // fill="#000"
                width={"15.8"}
                style={{ cursor: "pointer" }}
                color={isExpedientHovered ? "#0185F8" : "#002A4E"}
                fill={isExpedientHovered ? "#0185F8" : "#002A4E"}
                onMouseEnter={() => setIsExpedientHovered(true)}
                onMouseLeave={() => setIsExpedientHovered(false)}
              />
            </Tooltip>
          </Link>
        </>
      }
      {/* {movement.id === -1 && !!movement.id_tramite_mev && movement.has_files && ( */}
      {movement.id === -1 && movement.has_files && (
        <Tooltip trigger="hover" title="Descargar pdf">
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            size="small"
            onClick={() =>
              downloadPdf(
                `${baseURL}/file/download/${movement.files[0].id}`,
                movement.observations,
                "pdf"
              )
            }
          />
        </Tooltip>
      )}
      {movement.id_tramite_mev && (
        <>
          <Tooltip trigger="hover" title="Previsualizar">
            <EyeIcon
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                color: isPreviewHovered ? "#0185F8" : "#002A4E",
                padding: 0,
                border: "none",
                background: "none",
                fontSize: "17px"
              }}
              onMouseEnter={() => setIsPreviewHovered(true)}
              onMouseLeave={() => setIsPreviewHovered(false)}
              onClick={() => {
                setFileToPreview(movement.files[0])
                setPreviewFile(true)

              }}
            />
          </Tooltip>
          <Tooltip trigger="hover" title="Descargar proveido">
            <Button
              loading={loading}
              type="link"
              icon={
                <DownloadOutlined
                  style={{
                    color: isDownloadHovered ? "#0185F8" : "#002A4E",
                    fontSize: "17px"
                  }}
                />
              }
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                color: isDownloadHovered ? "#0185F8" : "#002A4E",
                padding: 0,
                border: "none",
                background: "none"
              }}
              onMouseEnter={() => setIsDownloadHovered(true)}
              onMouseLeave={() => setIsDownloadHovered(false)}
              onClick={() => {
                setLoading(true);
                downloadPdf(
                  `${baseURL}/file/download/${movement.files[0].id}`,
                  `${movement.files[0].name}`.replace(".pdf", "")
                ).finally(() => setLoading(false));
              }
              }
            />
          </Tooltip>
        </>
      )}
      {movement.id === -1 && (
        <ToggleInstaAccionScraping
          scrapingMovement={movement}
          onUpdated={movement => onPostponed(movement)}
        />
      )}
      {!!movement.id_tramite_mev && <ToggleInstaAccionMevScraping movement={movement} onUpdated={() => onPostponed(movement)} key={movement.id} />}
      {(movement.id !== -1 && !movement.id_tramite_mev) ? (
        <>
          <TogglePinMovement
            movement={movement}
            onUpdated={movement => onPostponed(movement)}
          />
          <Tooltip trigger="hover" title="Editar movimiento">
            <EditIcon
              width={"15.8"}
              style={{ cursor: "pointer" }}
              onClick={() => onEdit(movement)}
              color={isEditHovered ? "#0185F8" : "#002A4E"}
              onMouseEnter={() => setIsEditHovered(true)}
              onMouseLeave={() => setIsEditHovered(false)}
            />
          </Tooltip>
          {movement.movement_type_id !==
            Number.parseInt(
              process.env.REACT_APP_MOVEMENT_TYPE_ID_LEX_ESCRITO
            ) && (
              <PostponeConfirm
                postponeFunc={ExpedientMovementService.postponeMovement}
                itemType={"movimiento"}
                item={movement}
                isTabletOrMobile={isTabletOrMobile}
                onPostponed={movement => onPostponed(movement)}
              />
            )}
          <DeleteMovementConfirm
            deleteFunc={ExpedientMovementService.deleteExpedientMovement}
            itemType={"movimiento"}
            isTabletOrMobile={isTabletOrMobile}
            item={movement}
            onDelete={movement => onDelete(movement)}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MovementItem;
