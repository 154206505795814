import React, { useRef, useState } from "react";
import ExpedientService from "../../services/expedient.service";
import {
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { List, Typography, Badge, Popover, Input } from "antd";
import { withRouter } from "react-router-dom";
import ViewClient from "../../routes/ClientPage/ViewClient";
import CreateClientModal from "../../modals/CreateClientModal";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as CloseIcon } from '../../assets/assets2_0/close-icon.svg'
import { connect } from "react-redux";
import { ReactComponent as CandadoGris } from '../../assets/assets2_0/candado_gris.svg'

const SearchBox = props => {
  const { placeholder, onChange, value, isDefensoriaDesign, currentLang } = props;
  const [expedients, setExpedients] = useState(undefined);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);

  const [clientModal, showClientModal] = useState(false);
  const [currentClient, setCurrentClient] = useState(null);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' })

  const showSearchIcon = isTabletOrMobile ?
    <SearchOutlined
      onClick={() => inputRef.current.focus({
        cursor: 'all',
      })
      } style={{ fontSize: '18px' }} /> : null;

  const inputRef = useRef(null);

  const updateClients = c => {
    setClients(clients => {
      clients.map(_c => (_c.id === c.id ? c : _c));
    });
  };

  const ClientList = ({ clients }) => {
    return (
      <List
        header={`${currentLang.text.client}s`}
        dataSource={clients}
        renderItem={(client, idx) => (
          <List.Item
            key={idx}
            style={{
              cursor: "pointer"
            }}
            onClick={() => {
              setCurrentClient(client);
              showClientModal(true);
            }}
          >
            <Typography.Text
              style={{
                color: "#038FDE",
                fontWeight: "light",
                fontSize: "13px"
              }}
            >
              {client.firstname} {client.lastname}
            </Typography.Text>
            <Popover
              title={`Información del ${currentLang.text.client}`}
              trigger="hover"
              content={<ViewClient currentLang={currentLang} client={client} />}
            >
              <EyeOutlined style={{ alignSelf: "end" }} />
            </Popover>
          </List.Item>
        )}
      />
    );
  };

  return (
    <div className="search-input-holder">
      <CreateClientModal
        title={currentLang.text.client}
        visible={clientModal}
        client={currentClient}
        onUpdated={c => {
          updateClients(c);
          showClientModal(false);
          setCurrentClient(null);
        }}
        onCancel={() => {
          showClientModal(false);
          setCurrentClient(null);
        }}
      />
      <div style={{ lineHeight: '1px' }}>
        <Input

          addonBefore={expedients !== undefined ? <CloseIcon
            onClick={() => {
              setExpedients(undefined)
              setClients([])
            }}
            style={{
              paddingTop: '5px',
              cursor: 'pointer'
            }} /> : null}
          ref={inputRef}
          autoComplete="cc-csc"
          className={`mobile-advance-search search-input ${isDefensoriaDesign ? "defensoria-search-input-colors" : "default-search-input-colors"}`}
          type="search"
          suffix={showSearchIcon}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          id="search-input"
          onKeyUp={async e => {
            e.keyCode === 27 && setExpedients(undefined);
            if (e.keyCode === 13 && e.target.value !== "") {
              setLoading(true);
              const _expedients = await searchExpedients(e.target.value);
              setLoading(false);
              setExpedients({
                nuevo: _expedients.nuevo,
                tramite: _expedients.tramite
              });
              setClients(_expedients.clients);
            }
          }}
        />
        {/*         <span className="gx-search-icon gx-pointer">
          {loading ? (
            <Spin size="default" indicator={<LoadingOutlined />} />
          ) : expedients !== undefined ? (
            <CloseOutlined
              onClick={() => {
                setExpedients(undefined);
                clearText();
              }}
            />
          ) : (
            <i className="icon icon-search" />
          )}
        </span> */}
      </div>
      {expedients !== undefined && (
        <div
          onBlur={() => setExpedients(undefined)}
          className="searchbar-results"
        >
          {expedients.nuevo.length > 0 && (
            <ResultList
              header=""
              props={props}
              expedients={expedients.nuevo}
              hideList={() => setExpedients(undefined)}
            />
          )}
          {/*           {expedients.tramite.length > 0 && <ResultList
            header=""
            props={props}
            expedients={expedients.tramite}
            hideList={() => setExpedients(undefined)}
          />} */}
          <ClientList
            props={props}
            clients={clients}
            hideList={() => setExpedients(undefined)}
          />
        </div>
      )}
    </div>
  );
};

const ResultList = ({ expedients, props, hideList, header }) => {
  return (
    <List
      header={header}
      dataSource={expedients}
      renderItem={(exp, idx) => (
        <List.Item
          key={idx}
          style={{
            cursor: "pointer",
            flexFlow: "column",
            alignItems: "flex-start"
          }}
          onClick={() => {
            props.history.push(`/expedient/details#${exp.uuid}`);
            props.clearText();
            hideList();
          }}
        >
          <Typography.Text
            style={{ color: "#24335D", fontWeight: "light", fontSize: "13px" }}
          >
            {exp.front}
            {exp.is_public === 0 && <CandadoGris height="15" />}
          </Typography.Text>
          <Badge
            text={<span style={{ fontSize: "0.7rem" }}>{exp.status.name}</span>}
            color={exp.status.color}
          ></Badge>
        </List.Item>
      )}
    />
  );
};

async function searchExpedients(searchParam) {
  const expedients = await ExpedientService.searchExpedient(searchParam);
  return expedients.data;
}

const mapStateToProps = ({ designMode, lang }) => {
  const { isDefensoriaDesign } = designMode;
  const { currentLang } = lang
  return { isDefensoriaDesign, currentLang };
};

export default connect(mapStateToProps, {})(withRouter(SearchBox));

SearchBox.defaultProps = {
  styleName: "",
  value: "",
};
