import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as MenuIcon } from "../../../assets/assets2_0/menu.svg";
import { ReactComponent as SearchIcon } from "../../../assets/assets2_0/search.svg";
import { ReactComponent as CancelSearchIcon } from "../../../assets/assets2_0/cancel-search.svg";
import UserInfo, { GoogleQuickLogin } from "../../../components/UserInfo";
import { CurrentDate, NotificationsComponent, UnreadedInMails } from ".";
import { connect } from "react-redux";
import { toggleCollapsedSideNav } from "../../../appRedux/actions/Setting";
import TicketModal from "../../../law-components/Ticket";
import SearchBox from "components/SearchBox";
import { ExpedientFilter } from "./ExpedientFilter";
import CreateProfile from "../../../law-components/Forms/CreateProfile";
import { Modal, Typography } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { history } from "../../../appRedux/store";

const HeaderMobile = ({ toggleCollapsedSideNav, authUser, isDefensoriaDesign }) => {

  const [showSearchBar, setShowSearchBar] = useState(false)
  const [showProfile, setShowProfile] = useState(false)

  const isWindowedEditor = history.location.pathname.includes("windowed/rtf-editor");

  const HeaderContainer = styled.div`
    height: 100px;
    background-color:  ${p => p.isDefensoriaDesign ? '#B22A4D' : '#3D529E'};
    width: 100vw;
    border-radius: 0px 0px 10px 10px;
    display: flex;
    flex-flow: column;
    z-index: 51;
  `;

  const MobileToolbar = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    height: 60px;
  `;

  const SearchButtonMobile = styled.button`
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: grid;
    border: none;
    place-content: center;
    background-color:  ${p => p.isDefensoriaDesign ? "#F0869D" : '#3D529E'};
    cursor: pointer;
  `;

  const HeaderDivider = styled.div`
    width: 100vw;
    height: 2px;
    background-color: ${p => p.isDefensoriaDesign ? "#F0869D" : '#3D529E'};
  `;

  const DateContainer = styled.div`
    width: 100vw;
    height: 38px;
    display: grid;
    place-content: center;
  `;

  const MobileSearchBar = () => {

    const [searchText, setSearchText] = useState("")

    return (
      <SearchBox
        clearText={() => setSearchText('')}
        placeholder="Buscar"
        onChange={x => setSearchText(x.target.value)}
        value={searchText}
      />
    )
  }

  return (
    !isWindowedEditor ? <HeaderContainer isDefensoriaDesign={isDefensoriaDesign} >
      <MobileToolbar>
        <MenuIcon
          fill="#fff"
          style={{ cursor: "pointer" }}
          onClick={() => {
            toggleCollapsedSideNav();
          }}
        />
        {showSearchBar && <ExpedientFilter />}
        {showSearchBar && <MobileSearchBar />}
        <SearchButtonMobile isDefensoriaDesign={isDefensoriaDesign} onClick={() => setShowSearchBar(!showSearchBar)}>
          {showSearchBar ? <CancelSearchIcon className="mobile-header-cancel-icon" style={{ fontSize: '16px' }} fill="#fff" /> : <SearchIcon style={{ fontSize: '16px' }} fill="#fff" />}
        </SearchButtonMobile>
        {!showSearchBar && <NotificationsComponent user={authUser} />}
        {process.env.REACT_APP_SHOW_TICKETS === "1" && !showSearchBar &&
          authUser.role_id !== 1 && <TicketModal />}
        {!showSearchBar && <UnreadedInMails />}
        {!showSearchBar && <GoogleQuickLogin />}
        <Modal
          title={<Typography.Text style={{ fontWeigth: 700, color: "#0d94df" }} > Editar perfil</Typography.Text>}

          visible={showProfile}
          onCancel={() => setShowProfile(false)}
          footer={null}
          closeIcon={<CloseOutlined style={{ color: "#016ece" }} />}
          width={"90%"}
        >
          <CreateProfile user={authUser} />
        </Modal>
        <UserInfo
          hideName
          user={authUser}
          onProfileClick={() => setShowProfile(true)}
        />
      </MobileToolbar>
      <HeaderDivider isDefensoriaDesign={isDefensoriaDesign} />
      <DateContainer>
        <CurrentDate />
      </DateContainer>
    </HeaderContainer> : <></>
  );
};

const mapStateToProps = ({ settings, auth, designMode }) => {
  const { locale, } = settings;
  const { authUser } = auth;
  const { isDefensoriaDesign } = designMode;
  return { locale, authUser, isDefensoriaDesign };
};

export default connect(mapStateToProps, {
  toggleCollapsedSideNav
})(HeaderMobile);
