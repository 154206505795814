import React, { useContext, useEffect } from 'react'
import { TDurationInput } from './TDurationInput'
import { UserSelect } from '../../../law-components/PrebuiltFormComponents/Antd4UserSelect'
import { TurnoSelectorContext } from './TurnoSelectorContext'
import Antd4ClientSelect from '../../LettersPage/components/antd4ClientSelect'
import { Input, Typography } from 'antd'
import AppointmentService from '../../../services/appointment.service'
import { connect } from 'react-redux'

function TDataInput({ authUser }) {

  const { setClient, client, setObservations, observations, setUser } = useContext(TurnoSelectorContext)


  useEffect(() => {
    setUser(authUser.id)
  }, [authUser.id])

  return <div className='t-input'>
    <TurnoUserSelect authUser={authUser} />
    <Typography.Text>Cliente <span style={{ color: 'red' }}>*</span></Typography.Text>
    <Antd4ClientSelect onSelect={setClient} value={client} onNewClient={c => setClient(c)} />
    <Typography.Text>Duración <span style={{ color: 'red' }}>*</span></Typography.Text>
    <TDurationInput />
    <Typography.Text>Observaciones</Typography.Text>
    <Input.TextArea cols={4} value={observations} onChange={(e) => setObservations(e.target.value)} />
  </div>
}

const TurnoUserSelect = () => {

  const { user, setUser, setDuration, setAvailableHours, setAvailableDays, setHour, setDate, isOverturn } = useContext(TurnoSelectorContext)
  const defaultDays = [0, 1, 2, 3, 4, 5, 6]

  useEffect(() => {
    const fetchConfig = async () => {
      const { data } = await AppointmentService.getUserSettings(user)
      setDuration(data.max_duration_of_appointments)
      setAvailableDays(data.available_days || defaultDays)
      setAvailableHours(!isOverturn ? (data.available_hours || [0, 23]) : [0, 23])
      setHour(undefined)
      /* setDate(undefined) */
    }
    if (user) {
      fetchConfig()
    }
  }, [user, isOverturn])

  return [
    <Typography.Text>Abogado <span style={{ color: 'red' }}>*</span></Typography.Text>,
    <UserSelect currentUser={user} onSelect={setUser} key={'user'} />
  ]

}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser }
};

export default connect(mapStateToProps, {})(TDataInput);
